<template>
  <base-section id="aboutourproduct">
    <v-container>
      <v-row>
        <v-col>
          <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi velit
          in iste itaque exercitationem incidunt, odio iure? Provident quibusdam
          sed modi adipisci corporis omnis debitis ex error. Sequi, nostrum
          tempore? -->
        </v-col>
      </v-row>
    </v-container>
    <base-section-heading color="grey lighten-2" title="Servicios" />
    <v-container>
      <v-row>
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutOurProduct",
  data: () => ({
    cards: [
      {
        title: "Aperturasx",
        // subtitle: 'Subtitulo',
        text:
          "Aperturas de Urgencias las 24 horas, en toda clase de puertas, incluidas las acorazadas, blindadas y de vehículos, sin causar algún daño.",
        callout: "1"
      },
      {
        title: "Sustitución de cerraduras y bombín",
        // subtitle: 'Subtitulo',
        text:
          "Se realizan cuando ameritan cambiar la combinación de la cerradura. Otro motivo para sustituir el bombín es porque son piezas de seguridad muy importantes y un problema de funcionamiento en las mismas puede llevar al bloqueo de la puerta.",
        callout: "2"
      },
      {
        title: "Duplicado de llaves",
        // subtitle: 'Subtitulo',
        text:
          "Realizamos todo tipo de duplicado de llaves y ofrecemos el servicio a domicilio.",
        callout: "3"
      },
      {
        title: "Instalación de mirillas de seguridad",
        // subtitle: 'Subtitulo',
        text:
          "Han sido creadas con el objetivo de saber quién llama a la puerta por razones obvias de seguridad. La mirilla es un complemento necesario para evitar riesgos innecesarios.",
        callout: "4"
      },
      {
        title: "Amaestramiento",
        // subtitle: 'Subtitulo',
        text:
          "El amaestramiento de llaves consiste una técnica donde se le da prioridad a una llave para que pueda abrir diversas puertas a pesar de qué las cerraduras de las mismas tengan diversas combinaciones.",
        callout: "5"
      },
      {
        title: "Igualaciones",
        // subtitle: 'Subtitulo',
        text:
          "El igualamiento de llaves consiste en igualar dos o más cilindros para que se puedan abrir con la misma llave. Es la técnica ideal para utilizar en zonas comunes de viviendas, centros comerciales, oficinas, colegios, hospitales, viviendas unifamiliares, etc.",
        callout: "6"
      },
      {
        title: "Reparaciones",
        // subtitle: 'Subtitulo',
        text:
          "Es un servicio donde reparamos todo tipo de cerraduras, bombines, candados, cajas fuertes, sustituyendo las piezas dañadas de la cerradura y garantizando su total funcionalidad.",
        callout: "7"
      }
    ]
  })
};
</script>
